<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-html="showDetails(dbRowData)"></div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "General",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterImportGeneralDetails",
      isLoading: false,
      repository: "import_detail",
      resource: "import_details",
      tableRef: "ImportGeneralDetailsTableRef",
      args: {},
      fields: [
        {
          key: "detail",
          label: this.getDictionary("details", "import_log"),
        },
        {
          key: "code",
          label: this.getDictionary("code", "import_detail"),
        },
        {
          key: "title",
          label: this.getDictionary("title", "import_detail"),
        },
        {
          key: "is_error.text",
          label: this.getDictionary("is_error", "import_detail"),
        },
      ],
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    showDetails(dbRowData) {
      if (!dbRowData?.details) return "Non ci sono dettagli da mostrare.";
      return atob(dbRowData.details);
    },
  },
};
</script>
