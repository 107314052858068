<template>
  <div class="mt-1 mx-3">
    <!-- <import> </import> -->
    <import-annulli-notturni> </import-annulli-notturni>
  </div>
</template>

<script>
// import Import from '@/components/log/import/index.vue';
import ImportAnnulliNotturni from "@/components/log/import-annulli-notturni/index.vue";
import { mapActions } from "vuex";
export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    ImportAnnulliNotturni,
  },
  created() {
    this.notifyQueue({ text: "Import", path: "import", level: 1 });
  },
};
</script>
