<template>
  <div class="mx-1">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          type="button"
          variant="lisaweb"
          size="sm"
          @click="onRefreshContent(filterName)"
          title="Refresh"
          ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon
          >Ricarica</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="insurers"
                label="Compagnia"
                :options="insurers"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="status_import_log"
                label="Stato Importazione"
                :options="status_jobspool"
                v-model="filter.byAttribute.status_jobspool"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <job-spools
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: ['byInsurer', 'byImportLog'],
      }"
      :filterName="filterName"
      :ref="tableRef"
      sortField="last_update"
      noActions
      descending
    ></job-spools>
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import JobSpools from "@/components/tables/JobSpools.vue";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "import_log",
      resource: "import_logs",
      filterName: "filterImportLogsLegacy",
      tableRef: "ImportLogsLegacyImportTableRef",
      filter: this.initFilter(),
      current_config: {},
      insurers: [],
      status_import_log: [
        { value: 0, text: "In esecuzione" },
        { value: 1, text: "Terminato con errori" },
        { value: 2, text: "Terminato con successo" },
      ],
      status_jobspool: [
        { value: 0, text: "Upload caricato" },
        { value: 1, text: "Caricamento via API in corso" },
        { value: 2, text: "Caricamento via API terminato" },
        { value: 3, text: "Smistamento al servizio di code" },
        { value: 4, text: "Trasferito al servizio di code" },
        { value: 5, text: "Elaborazione in corso" },
        { value: 6, text: "Elaborazione terminata con successo" },
        { value: 7, text: "Elaborazione terminata con errori" },
      ],
      fields: [
        // {
        //   key: "detail",
        //   label: this.getDictionary("details", "import_log"),
        // },
        {
          key: "insurer_id",
          label: this.getDictionary("title", "insurer"),
          formatter: (value) =>
            this.insurers.find((el) => el.value === value).text,
        },
        {
          key: "status_importlog.text",
          label: this.getDictionary("status_import_log", "import_log"),
        },
        {
          key: "last_update",
          label: this.getDictionary("last_update", "import_log"),
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
        },
      ],
    };
  },
  components: {
    BaseSelect,
    JobSpools,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          status_jobspool: null,
          job_type: 0,
        },
        byInsurer: {
          id: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onRefreshContent(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].fetch(this.current_config);
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].fetch(this.current_config);
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "last_update";
      this.$refs[this.tableRef].sortDesc = true;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
  },
  mounted() {
    this.current_config.verb = {
      name: "legacy",
      canVerb: this.canVerb("import_logs", "index"),
    };
    // this.$refs[this.tableRef].fetch(this.current_config);
    this.insurers = this.getInsurers();
  },
};
</script>
