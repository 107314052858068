<template>
  <div class="mt-1 mx-3">
    <import> </import>
  </div>
</template>

<script>
import Import from "@/components/log/import/index.vue";
import { mapActions } from "vuex";
export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Import,
  },
  created() {
    this.notifyQueue({ text: "System Import", path: "system", level: 1 });
  },
};
</script>
