<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(insurer_id)="row">
            {{ row.item.insurer_id ? retriveInsurerInfo(row.item) : "-" }}
          </template>
          <template #cell(type_description)="row">
            <span class="uc-first">{{
              manipulateTypeDescription(row.item)
            }}</span>
          </template>
          <template #cell(detail)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                class="mt-1 mr-1"
                title="Visualizza dettagli"
                @click="
                  customOpenDetailsModal(row.item, row.index, $event.target)
                "
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>

      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>
      <b-modal
        :dialog-class="customModalClass"
        :id="infomodalName"
        :title="custoModalTitle"
        :size="custoModalSize"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>

              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  :dbRowData="dbRowData"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/log/import-annulli-notturni/details/General";
import client from "@/repositories/api";

export default {
  name: "AnnulliNotturniTable",
  extends: template,
  data() {
    return {
      args: null,
      importers: [],
      selected: "General",
      modalResourceId: null,
      customModalClass: "modal-dettagli-importatori",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      custoModalTitle: "Dettagli",
      custoModalSize: "md",
      modalTitleDefault: "Dettagli",
      modalSizeDefault: "md",
      tabIndex: 0,
      tabs: [{ name: "General", text: "Dettagli" }],
      dbRowData: null,
    };
  },
  components: {
    BaseIcon,
    ButtonGroupTab,
    General,
  },
  methods: {
    customOpenDetailsModal(item, index, target) {
      // this.tabIndex = 0; // TabIndex ID dei Dettagli
      // this.custoModalTitle = this.modalTitleGroup;
      // this.custoModalSize = this.modalSizeGroup;
      this.customModalClass += " custom-details-tab-open";
      this.openModal(item, index, target);
    },
    customized(item) {
      let c = {};
      c.descrizione = item.description;
      c.titolo = item.title;
      return c;
    },
    manipulateTypeDescription(item) {
      if (!item.type_description) return "-";
      let str = item.type_description.replace(/_/g, " ");
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    },
    retriveInsurerInfo(item) {
      if (!item.insurer_id) return "-";
      return this.importers.find(
        (importer) => importer.value == item.insurer_id
      ).text;
    },
  },
  beforeMount() {
    this.args = this.$parent.$parent.args;
  },
  created() {
    this.isLoading = true;
    const url = `insurers/create`;
    client
      .get(url)
      .then((response) => {
        const fields = response.data.fields;
        console.log("response", response);
        console.log("fields", fields);
        if (fields?.importer_id?.options)
          this.importers = fields?.importer_id?.options;
      })
      .catch((error) => {
        console.error(error);
      });
    // .finally(() => {
    //   this.isLoading = false;
    // });
  },
};
</script>
<style lang="css">
.uc-first::first-letter {
  display: inline-block;
  text-transform: capitalize;
}
</style>
